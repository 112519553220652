<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="10" :offset="3">
                    <FormItem label="KEY" prop="key">
                        <Input :maxlength="30" show-word-limit v-model="form.key" />
                    </FormItem>

                    <FormItem label="名称" prop="label" class="w50">
                        <Input :maxlength="20" show-word-limit v-model="form.label" />
                    </FormItem>

                    <FormItem label="描述">
                        <Input :maxlength="60" show-word-limit v-model="form.desc" />
                    </FormItem>

                    <FormItem label="权重">
                        <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="10" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {

        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    key   : '',
                    label : '',
                    desc  : '',
                    weight: 0
                },
                validate: {
                    key: [
                        { required: true, message: '请输入KEY', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ]
                }
            }
        },
        created()
        {
            this.id && this.loadData()
        },
        methods: {
            submit()
            {
                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        const url = '/ruleExplain/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/ruleExplain/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                    })
            }
        }
    }
</script>